<template>
    <div>
    <v-dialog
        v-model="showingImportRipenerTextModal"
        width="auto"
        @input="close"
    >
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Seleccione un archivo .xls para importar contactos
            </v-card-title>

            <v-card-text class="pb-0" >
                <v-file-input
                v-model="ripenerTextFile" 
                @change="validateFile"
                webkitRelativePath
                accept=".xls" 
                ref="contact_file_input" 
                label="Seleccione un archivo..."
                ></v-file-input>
            </v-card-text>
            <span class="error_msg">
            {{error}}
            </span>
            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                color="primary" 
                text 
                @click="close()"
                >
                    CANCELAR
                </v-btn>         
                <v-btn
                ref="send-btn"
                color="success"
                @click="importRipenerText()"
                :disabled="error !== null || ripenerTextFile === null"
                >
                    Importar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog 
    v-model="alert"
    width="350"
    >
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Importando mensajes...
            </v-card-title>

            <v-card-text class="pb-0" >
                Sus modelos de mensaje para madurador se cargaron correctamente, este proceso puede tardar unos minutos para visualizarse en pantalla. 
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                color="primary" 
                @click="alert = false, close()"
                >
                    Ok
                </v-btn>         
                
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    data: () => {
        return {
            showingImportRipenerTextModal: false,
            ripenerTextFile: null,
            error: null,
            alert: false
        }
    },

    computed: {
            getUserRole() { return this.$store.getters['session/getUserRole']() },
            getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
        },

    methods: {
        open() {
            this.showingImportRipenerTextModal = true
        },

        close() {
            this.ripenerTextFile = null
            this.error= null
            this.showingImportRipenerTextModal = false
        },

        async importRipenerText(){
            try {
                const formData = new FormData()
                formData.append('file', this.ripenerTextFile)
                formData.append('company_id', this.getSelectedCompany)
                const response = await this.$api.ripenerTextImport(formData)
                    if(response.status === 200){  
                        this.$emit('reload-ripener-text-list')
                        this.alert =  true
                    }
            } catch(e) {
                e.response.data.error ? 
                this.error = e.response.data.error :
                this.error = 'Ocurrió un error. Intente más tarde.'

            }
        },
        validateFile(file) {
            let allowedExtensions = /(\.xls)$/i;
            if (!!file && !allowedExtensions.exec(file.name)) {
                this.error = 'Sólo se permiten archivos .xls'
            } else {
                this.error = null
            }
        }

    },
}
</script>
<style scoped>
.error_msg{
    color: red;
    margin-left: 25px;
}
</style>