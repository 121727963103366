import { render, staticRenderFns } from "./DeleteRipenerTextModal.vue?vue&type=template&id=15a5e122&"
import script from "./DeleteRipenerTextModal.vue?vue&type=script&lang=js&"
export * from "./DeleteRipenerTextModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports