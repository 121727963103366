<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
        v-model="searchQuery"
        @keyup="handleSearch"
        placeholder="Buscar por titulo o texto"
        outlined
        hide-details
        dense
        class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
					color="primary"
					class="mb-4 me-3 px-2 no-uppercase"
          @click="$refs.ripener_text_form_modal.open()"
          >
            <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon>
            <span>Crear Mensaje</span>
          </v-btn>

          <v-btn
					color="secondary"
					class="mb-4 me-3 px-2 no-uppercase"
          @click="$refs.import_ripener_text_modal.open()"
          >
            <v-icon>{{ icons.mdiFilePlusOutline }}</v-icon>
            <span>Importar</span>
          </v-btn>

          <v-btn
					color="error"
					class="mb-4 me-3 px-2 no-uppercase"
          @click="exportModelFile()"
          >
            <v-icon>{{ icons.mdiFilePlusOutline }}</v-icon>
            <span>Descargar archivo modelo</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
      :headers="tableColumns"
			:page="current_page"
      :items="ripenerTextListTable"
      :server-items-length="totalRipenerTextListTable"
      :loading="loading"
      @update:page="changingRipenerTextPage($event)"
      @update:items-per-page="changingRipenerTextItemsPerPage($event)"
			:footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      >

        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No hay
              <strong>Modelos de mensajes</strong>
              disponibles para esta búsqueda
            </v-list-item-title>
          </v-list-item>
        </template>

        <template #[`item.text`]="{item}">
          <span
          style="max-width: 200px;"
          class="d-inline-block text-truncate text-span"
          @click="showFullText(item.text)"
          >
            {{ item.text }}
          </span>
        </template>

        <!--Edit-->
        <template #[`item.edit`]="{item}">
					<v-btn
						icon
						color="primary"
            @click="$refs.ripener_text_form_modal.open(item)"
					>
						<v-icon>
							{{ icons.mdiSquareEditOutline }}
						</v-icon>
					</v-btn>
				</template>

                <!--Delete-->
        <template #[`item.delete`]="{item}">
					<v-btn
						icon
            @click="$refs.delete_ripener_text_modal.open(item)"
					>
						<v-icon class="error--text">
							{{ icons.mdiDeleteOutline }}
						</v-icon>
					</v-btn>
				</template>

        <template #[`item.status`]="{item}">
          {{ statusParser[item.status] }}
        </template>
      </v-data-table>
    </v-card>

    <template>
      <v-dialog v-model="showingFullTextModal" max-width="500">
        <v-card class="pa-3">
          <v-card-title class="d-flex justify-center">
              Texto completo
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
              {{ fullText }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showingFullTextModal = false">
            Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <RipenerTextFormModal ref="ripener_text_form_modal" @reload-ripener-text-list="getRipenerTextList()"/>
    <DeleteRipenerTextModal ref="delete_ripener_text_modal" @reload-ripener-text-list="getRipenerTextList()"/>
    <ImportRipenerTextModal ref="import_ripener_text_modal" @reload-ripener-text-list="getRipenerTextList()"/>
  </div>
</template>

<script>
const debounce = require('debounce');
import {ref} from '@vue/composition-api';
import RipenerTextFormModal from '@/components/RipenerText/RipenerTextFormModal'
import DeleteRipenerTextModal from '@/components/RipenerText/DeleteRipenerTextModal'
import ImportRipenerTextModal from '@/components/RipenerText/ImportRipenerTextModal'
import {
  mdiPlusCircleOutline,
  mdiFilePlusOutline,
  mdiSquareEditOutline,
  mdiDeleteOutline
} from '@mdi/js'
export default {
  data: () => {
    return {
      current_page: 1,
      page_size: 10,
      fullText: '',
      showingFullTextModal: false
    }
  },
  components: {
    RipenerTextFormModal,
    DeleteRipenerTextModal,
    ImportRipenerTextModal
  },
  methods: {
    async getRipenerTextList() {
      this.loading = true
      try {
        let query = {
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery
        }
        const response = await this.$api.getRipenerTextList(query)
        this.totalRipenerTextListTable = response.count
        this.ripenerTextListTable = response.results
      } catch(e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    changingRipenerTextPage(e) {
      this.current_page = e
      this.getRipenerTextList()
    },

    changingRipenerTextItemsPerPage(e) {
      this.page_size = e
      this.getRipenerTextList()
    },

    showFullText(text) {
      this.fullText = text
      this.showingFullTextModal = true
    },

    async exportModelFile() {
      const data = await this.$api.exportModelFile()
      this.clickCreatedLink(data)
    },

    clickCreatedLink(data) {
      const blob = new Blob([data], { type: 'text/html;charset=utf-8;' });
      const url = URL.createObjectURL(blob)
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `Archivo modelo.csv`);
      link.click();
    },

    handleSearch: debounce(function (e) {
      this.current_page = 1
      this.getRipenerTextList()
    }, 400),
  },
  mounted() {
    if(this.getSelectedCompany) {
      this.getRipenerTextList()
    }
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
    statusParser() {
      const parser = {
        0: 'Activo',
        1: 'Inactivo'
      }
      return parser
    }
  },
  watch: {
    getSelectedCompany(newValue) {
      if(newValue) {
        this.current_page = 1
        this.page_size = 10
        this.getRipenerTextList()
      }
    }
  },
  setup() {
    const totalRipenerTextListTable = ref(1);
    const ripenerTextListTable = ref([]);
    const searchQuery = ref('')

    const tableColumns = [
    { text: 'Id', value: 'id', align: 'center', sortable: false },
    { text: 'Titulo', value: 'name', align: 'center', sortable: false },
    { text: 'Texto', value: 'text', align: 'center', sortable: false },
    { text: 'Estado', value: 'status', align: 'center', sortable: false },
    { text: 'Editar', value: 'edit', align: 'center', sortable: false },
    { text: 'Eliminar', value: 'delete', align: 'center', sortable: false },
    ];

    const loading = ref(false);
    return {
      loading,
      tableColumns,
      totalRipenerTextListTable,
      ripenerTextListTable,
      searchQuery,
      icons: {
        mdiPlusCircleOutline,
        mdiFilePlusOutline,
        mdiSquareEditOutline,
        mdiDeleteOutline
      }
    }
  }
}
</script>

<style scoped>
.text-span {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
}

.text-span:hover {
  background-color: rgba(185, 185, 185, 0.3);
}
</style>
