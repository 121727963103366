<template>
  <div>
      <v-card>
          <v-card-title>Modelos de mensajes</v-card-title>
          <RipenerTextList></RipenerTextList>
      </v-card>
  </div>
</template>

<script>
import RipenerTextList from '@/components/RipenerText/RipenerTextList';

export default {
  components: {
    RipenerTextList
  }
}

</script>