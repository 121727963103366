<template>
  <div class="drag-and-drop-container">
    <div class="dropbox">
      <input type="file" id='img_file' ref='img_file' multiple
        @change="handleFilesUpload(); fileCount = $event.target.files.length"
        :name="uploadFieldName" :accept="acceptedFiles" height="600px" class="input-file"/>
      <p v-if="!arrayFiles.length">
        Arrastre su(s) archivo(s) aquí para adjuntarlos.<br>
      </p>

      <div v-else style="padding: 1rem">
        <div class="file-name d-flex justify-space-between align-center" v-for="(file, idx) in arrayFiles" :key="`${file.lastModified}_${idx}`">
            <div>{{ file.name }} </div> <v-btn
            @click="remove(idx)"
            class="mr-auto"
            icon
            color="error"
            small
            ><v-icon>{{icons.mdiCloseCircleOutline}}</v-icon></v-btn>
        </div>
      </div>


    </div>
    <div v-if="arrayFiles.length" style="text-align: right">
      <span @click="reset()" class="clear-files">Borrar archivos</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import Spinner from "~/components/common/Spinner";
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import { mdiCloseCircleOutline } from '@mdi/js';


export default {
  data() {
    return {
      fileCount: 0,
      totalFileCount: 0,
      arrayCurrentFiles:[],
      arrayFiles: [],
      uploadError: null,
      uploadFieldName: 'file',
      maxImgSize: 1024 * 1024 * 100, // 100Mb.
    }
  },
  components: {
    axios,
    // Spinner
    AppCardActions
  },
  computed: {
    acceptedFiles() { return process.env.VUE_APP_ACCEPTED_FILES }
  },
  methods: {
    handleFilesUpload() {
      const files = this.$refs.img_file.files
      let valid = true

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > this.maxImgSize) {
          // console.log('file grande')
          valid = false
        }
      }

      if (valid) {
        this.arrayCurrentFiles = this.$refs.img_file.files
        this.arrayFiles = [
          ...this.arrayCurrentFiles,
          ...this.arrayFiles
        ]
        this.$emit("files-to-send", this.arrayFiles)
      }
    },

    remove(idx) {
      this.arrayFiles.splice(idx, 1)
      this.$emit("files-to-send", this.arrayFiles)
    },


    reset() {
      this.fileCount = 0;
      this.totalFileCount= 0,
      this.arrayFiles = [];
      this.uploadError = null;

      this.$emit("files-to-send", this.arrayFiles)
    },


  },
  mounted() {
    this.reset();
  },
  props: {
    review: {
      type: Object,
      required: false,
    }
  },

  setup() {
    return {
      icons: {
        mdiCloseCircleOutline
      }
    }
  }
}

</script>

<style scoped>
  .drag-and-drop-container {
    height: 100%;
    padding-bottom: 30px;
  }

  .invalid-feedback{margin-bottom:5px;}
  .pr-1{padding-right: 1rem!important;}
  .pl-1{padding-left: 1rem!important;}
  .w-45 {width: 48%;}
  .white-modal {
    justify-content: space-between;
  }
  .list-unstyled {
    text-align: center;
    max-height: 80px;
    overflow: auto;
  }
  .p-height{
    min-height: 80px;
  }
  .modal-body{
    min-height: 200px;
    max-height: 370px;

  }
  .close {
    display: none
  }

  .dropbox {
    height: 100%;
    outline: 2px dashed rgb(217, 217, 217);
    outline-offset: 1px;
    color: rgb(188, 188, 188);
    padding: 10px 10px;
    min-height: 200px;
    position: relative;
    cursor: pointer;
    margin:0px;
    padding:0px;
  }
  .dropbox > input {
    box-sizing: border-box;
  }

  .input-file {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: #faf6ff;
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    display: grid;
    place-content: center;
    height: 100%;
  }

  .file-name {
    font-size: 1em;
    text-align: left;
  }

  .clear-files {
    display: inline-block;
    margin-top: 0.5rem;
    cursor: pointer;
    color: rgb(188, 188, 188);;
  }
  .clear-files:hover {
    color: rgb(55 50 62 / 68%);
  }


</style>
