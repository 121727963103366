<template>
  <v-dialog v-model="showingRipenerTextFormModal" width="700" scrollable persistent minHeight="1200">
    <v-form ref="ripenerTextForm" @submit.prevent="createRipenerText">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{editRipenerTextItem ? 'Editar modelo de mensaje' : 'Crear modelo de mensaje'}}
        </v-card-title>
  
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-text-field
                ref="name"
                v-model="form_name"
                :rules="[v => !!v || 'Nombre requerido']"
                outlined
                dense
                autocomplete="off"
                hide-details
                label="Titulo del mensaje"
                ></v-text-field>
            </v-col>
  
            <v-col cols="12" md="12">
              <v-switch
                class="pt-0 mt-0"
                v-model="form_status"
                :true-value="0"
                :false-value="1"
                hide-details
                :label="form_status === 0 ? 'Mensaje Activo' : 'Mensaje Inactivo'"
              ></v-switch>
            </v-col>
  
            <v-col cols="12">
              <v-textarea
                ref="text"
                v-model="form_text"
                :rules="[v => !!v || 'Texto requerido']"
                outlined
                dense
                autocomplete="off"
                hide-details
                label="Texto del mensaje"
                ></v-textarea>
            </v-col>
  
            <v-col cols="12" md="12">
              <div v-if="editRipenerTextItem && filesToEdit.length" class="mb-3">
                <div>
                  Editar archivos subidos anteriormente.
                </div>
                <div class="d-flex flex-wrap grey-border-top" >
                  <v-chip v-for="file in filesToEdit" class="ma-1">
                    {{ file.name }}<v-btn @click="removeFile(file.id)" x-small icon color="error"><v-icon>{{ icons.mdiClose }}</v-icon></v-btn>
                  </v-chip>
                </div>
              </div>
              <drag-and-drop ref="drag_and_drop"
              @files-to-send="bindFilesToSend"
              />
  
            </v-col>
  
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn color="primary" text @click="closeModal()">
                CANCELAR
            </v-btn>
  
            <v-btn color="primary" type="submit">
                {{editRipenerTextItem ? 'EDITAR MENSAJE' :'CREAR MENSAJE' }}
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-form>
    <alert-pop-up ref="alert_pop_up" />
  </v-dialog>
</template>

<script>
import {
  mdiClose
} from '@mdi/js';
import AlertPopUp  from '@/components/AlertPopUp.vue';
import DragAndDrop from '@/components/Messages/DragAndDrop';


export default {
  data: () => {
    return {
      form_name: '',
      form_text: '',
      form_status: 1,
      showingRipenerTextFormModal: false,
      editRipenerTextItem: null,
      filesToSend: [],
      filesToEdit: [],
      files_delete: []
    }
  },
  methods: {
    open(item_to_edit) {
      item_to_edit 
      ? this.setItemToEdit(item_to_edit)
      : this.showingRipenerTextFormModal = true
    },
    closeModal() {
      this.$refs.name.reset()
      this.$refs.text.reset()
      this.$refs.drag_and_drop.reset()
      this.form_status = 1
      this.filesToSend = []
      this.editRipenerTextItem = null
      this.showingRipenerTextFormModal = false
      this.filesToEdit = []
      this.files_delete = []
    },
    setItemToEdit(item) {
      this.editRipenerTextItem = item
      if(item) {
        this.form_name = item.name
        this.form_text = item.text
        this.form_status = item.status
        this.filesToEdit = item.files
      }
      this.showingRipenerTextFormModal = true
    },
    async createRipenerText() {
      if(this.$refs.ripenerTextForm.validate()) {
        try {
          const formData = new FormData()
          if (this.filesToSend.length) {
            for (let i = 0; i < this.filesToSend.length; i++) {
              formData.append('files', this.filesToSend[i])
            }
          }
  
          let form = {
            name: this.form_name,
            text: this.form_text,
            company_id: JSON.stringify(this.getSelectedCompany),
            status: this.form_status
          }
  
          if(this.editRipenerTextItem) {
            form.files_delete = JSON.stringify(this.files_delete)
          }
  
          for(const key in form) {
            formData.set(key, form[key])
          }
  
          const response =  this.editRipenerTextItem
                            ? await this.$api.updateRipenerText(formData, this.editRipenerTextItem.id)
                            : await this.$api.createRipenerText(formData)
  
          if(response.status === 200) {
            this.$refs.alert_pop_up.showPopUp({ message: response.data.message, type: 'success' })
          }
          this.$emit('reload-ripener-text-list')
          this.closeModal()
        } catch(e) {
          console.log(e)
          if (e?.response?.status === 403) {
            this.$refs.alert_pop_up.showPopUp({ message: e?.response?.data?.message, type: 'error'})
          }
        }
      }
    },

    bindFilesToSend(files) {
      this.filesToSend = files
    },

    removeFile(id) {
      this.filesToEdit = this.filesToEdit.filter(el => el.id !== id)
      this.files_delete.push(id)
    }
  },
  components: {
    AlertPopUp,
    DragAndDrop
  },
  computed: {
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
  },
  setup() {
    return {
      icons: {
        mdiClose
      }
    }
  }
}
</script>