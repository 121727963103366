<template>
  <v-dialog
      v-model="showingDeleteRipenerTextModal"
      width="auto"
  >
      <v-card>
          <v-card-title class="text-h5 grey lighten-2">
              ¿Estás seguro de eliminar el modelo de mensaje '{{ ripenerText.name }}'?
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn 
              color="primary" 
              text 
              @click="close()"
              >
                  CANCELAR
              </v-btn>         
              <v-btn
              color="error"
              @click="deleteRipenerText()"
              >
                  Eliminar
              </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => {
      return {
          showingDeleteRipenerTextModal: false,
          ripenerText: {}
      }
  },

  methods: {
      open(ripenerText) {
          this.showingDeleteRipenerTextModal = true
          this.ripenerText = ripenerText
      },

      close() {
          this.showingDeleteRipenerTextModal = false
      },

      async deleteRipenerText() {
          try {
              const response = await this.$api.deleteRipenerText(this.ripenerText.id)

              this.$emit('reload-ripener-text-list')
              this.close()
          } catch(e) {
              console.log(e)
          }
      }
  }
}
</script>